import React from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const DialogPleaseWait = (props) => {
    return (
        <Dialog open={props.open}  >
            <DialogContent className="commonWidth">


                <Grid align="center" container spacing={1} >
                    <Box alignItems="center"
                        justifyContent="center">
                        <CircularProgress />
                        <Typography style={{ paddingTop: '10px', paddingLeft: '35px' }}>Please wait ...</Typography>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default DialogPleaseWait;
