import React from 'react';
import { Box, Typography } from '@material-ui/core';

const TermsOfUse = (props) => {

    return (
        <>
            <Typography component='div' variant="h6" align="center" style={{ flexGrow: 1, fontWeight: 'bold', color: "brown" }}>Online
             Dispute Resolution Portal Terms of Use</Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Notice </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Please read these terms and conditions carefully.
             By accessing this Online Dispute Resolution Portal (as defined below) found on DinersClub.com (“<Box fontWeight='fontWeightMedium' display='inline'>Online Dispute
             Resolution Portal</Box>”) hosted on the Diners Club International Ltd. (“<Box fontWeight='fontWeightMedium' display='inline'>Diners Club</Box>”) digital or mobile
             platform, including but not limited to the mobile application, website and any pages, modules or content found on the Online Dispute Resolution Portal,
             you agree to be bound by the terms and conditions below. If you do not agree to the terms and conditions below, do not access the Diners Club website and/or the
             Online Dispute Resolution Portal, mobile application, or any other Diners Club platform or channel through which Online Dispute Resolution Portal is accessible.
             Diners Club reserves the right to change these terms of use at any time and without notice. We request you to carefully read through these terms of
              use prior to using the Online Dispute Resolution Portal. If you access and continue to browse, and use the Online Dispute Resolution Portal, you irrevocably
              and unconditionally are agreeing to comply with, abide by and be bound by all the obligations as stipulated in these terms of use,
             Diners Club website general terms of use available at DinersClub.com (“<Box fontWeight='fontWeightMedium' display='inline'>General ToU</Box>”),
             and our Online Dispute Resolution Portal specific privacy policy available at DinersClub.com and any other applicable policies referred to herein or made
             available on the Online Dispute Resolution Portal. In case of conflict between these terms of use, i.e., the “<Box fontWeight='fontWeightMedium' display='inline'>
                    Online Dispute Resolution Portal ToU</Box>” and the General ToU, the provisions of Online Dispute Resolution Portal
             ToU shall prevail but only as regard to the Online Dispute Resolution Portal. In all other cases, the General ToU shall prevail over the Online Dispute
             Resolution Portal ToU.
              Further, it shall be your liability and responsibility to comply with the terms and conditions of all such third-party platforms. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Only Diners Club card holders to whom the Diners Club cards have been issued by an
            issuing bank in India
            (“<Box fontWeight='fontWeightMedium' display='inline'>India Cardholders</Box>”) are permitted to access this Online Dispute Resolution Portal.
            In case you do not fall under the abovementioned category then you are not permitted to access this Online Dispute Resolution Portal, and you should refrain
            from accessing the Online Dispute Resolution Portal. Copyright © 2022 Diners Club International Ltd. All Rights Reserved </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Copyright in the pages and in the screens displaying the pages, and in the Information and
            material therein and in their arrangement, is owned by Diners Club International Ltd. (“Diners Club”) unless otherwise indicated. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Trademarks </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Belong, Club Cash, Global Vision, Diners, Diners Club, Diners Club International, Diners Club
            International with Split Circle Logo and Split Circle Logo are trademarks and/or service marks of Diners Club and are used and registered throughout the world.
            Diners Club, its parent and affiliate companies may also claim rights in certain other trademarks and service marks contained in these web pages. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Online Dispute Resolution
            Portal </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>This Online Dispute Resolution Portal has been provided by Diners Club for resolving disputes and
            grievances arising out of failed transactions of any of the India Cardholders in a user-friendly and transparent manner. The scope of this Online Dispute Resolution
            Portal is to redress all disputes and grievances arising out of transaction types mentioned in Reserve Bank of India’s
            circular DPSS.CO.PD No.629/02.01.014/2019-20 dated September 20, 2019, on “Harmonisation of Turn Around Time (TAT) and customer compensation for failed transactions
            using authorised Payment Systems”. </Typography>
        </>
    )
}
export default TermsOfUse;
