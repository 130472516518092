import { useContext } from 'react'
import AppContext from "../../context/appContext";
import {complaintEnquiry} from "../services/DisputeService";

export const StatusEnquiryHelper = () => {
    const { setDisputeData } = useContext(AppContext);

    async function checkComplaintStatus({complaintId, openPlsWait, closePlsWait,
        openInvDetail, openSessionExpiredDialog, openSysDown}) {
        let res;
        try {
            openPlsWait();
            res = await complaintEnquiry(complaintId);
            if (res.status === 200) {
                const statusEnquiryData = {
                    complaintId: res.data.complaintId,
                    complaintStatus: res.data.complaintStatus
                };

                setDisputeData(statusEnquiryData);
                setTimeout(() =>
                    window.location.hash = '/disputestatus', 0);
            }
        } catch (err) {
            closePlsWait();
            if (err?.response?.status === 404) {
                openInvDetail();
            } else if (err?.response?.status === 401) {
                openSessionExpiredDialog();
            } else {
                openSysDown();
            }
        }
    }

    return { checkComplaintStatus }
}
