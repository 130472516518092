import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FailedTransactionReason = (props) => {
    return (
        <>
            <Grid item xs={12} sm={6} md={3}>
                <Typography className="raiseDisputeLabel">Failed Transaction Reason</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Autocomplete paddingbottom={14}
                    id="failedTransactionReason" className="currencySelect"
                    name="failedTransactionReason"
                    value={props.reasonTxt}
                    options={props.reasonItems}
                    getOptionLabel={option => option.description}
                    onChange={(e, newvalue) => {
                        if (newvalue !== null) {
                            props.setDirty();
                            props.setReasonTxt(newvalue);
                            props.formik.values.failedTransactionReason = newvalue.code;
                        } else {
                            props.setReasonTxt('');
                            props.formik.values.failedTransactionReason = "";
                        }

                    }}
                    renderInput={params => (
                        <TextField {...params} name="failedTransactionReason" label="Please Select" size="small" onChange={props.handleOnchange}
                            variant="outlined" value={props.formik.values.failedTransactionReason} className="currencySelect"
                            helperText={props.formik.touched.failedTransactionReason && props.formik.errors.failedTransactionReason}
                            error={Boolean(props.formik.touched.failedTransactionReason && props.formik.errors.failedTransactionReason)}
                        />
                    )}
                />
            </Grid>
        </>
    )
}
export default FailedTransactionReason;
