import axios from 'axios';
import store from '../../store/store';
import { serviceEndpoints } from './ServiceEndpoints';


const endpoint = window?._env_?.REACT_APP_BACKEND_API;

const api = () => {

    const sessionIdFromStore = store.getState().sessionId;
    console.log("Api URl: " + endpoint);

    const axiosInstance = axios.create({
        baseURL: endpoint
        // timeout: 10000

    });

    axiosInstance.interceptors.request.use(function (config) {

        if (config.url !== serviceEndpoints.verifyCardholder) {
            config.headers['Session-Id'] = sessionIdFromStore;
        }
        return config;
    });

    return axiosInstance;

}
export default api;
