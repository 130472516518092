const AppReducer = (state, action) => {
    if (action.type === "SET_DISPUETEDATA") {
        return {
            ...state,
            disputeData: action.payload
        }
    } else {
        return state;
    }

};
export default AppReducer;
