import React from 'react';
import { Grid, Box, TextField, Button, Dialog, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ColoredLine from './ColoredLine';


const DialogOpenOtp = (props) => {
    return (
        <Dialog open={props.open}>
            <form id="submitOtp" onSubmit={props.otpFormik.handleSubmit} onReset={props.otpFormik.resetForm}>
                <Box className="successPopup">
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <Typography className="successPopupHeader">Let's make sure it's you</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <DialogContent>

                    <Grid container spacing={1} style={{ paddingBottom: '25px', paddingTop: '15px' }}>
                        <Grid item xs={12}>
                            {!props.invalidOtpEntered && <Typography>We have sent a verification code to your mobile, please enter the code to continue.</Typography>}
                            {props.invalidOtpEntered && <Typography color="secondary">The verification code you entered is invalid.
                            Please enter the correct code and try again.</Typography>}
                            <p></p>
                            <TextField label="Required" id="otpCode" name="otpCode"
                                type="text" variant="outlined" size="small" style={{ width: 245 }}
                                value={props.otpFormik.values.otpCode}
                                onChange={props.otpFormik.handleChange}
                                error={props.otpFormik.touched.otpCode && Boolean(props.otpFormik.errors.otpCode)}
                                helperText={props.otpFormik.touched.otpCode && props.otpFormik.errors.otpCode}
                            >OTP</TextField>
                        </Grid>
                    </Grid>




                </DialogContent>
                <Grid align="center" style={{ paddingBottom: '10px' }}>
                    <ColoredLine color="grey" />
                    <Button variant="outlined" className="sucessPopupButton" type="submit">Validate</Button>

                </Grid>
            </form>
        </Dialog>
    )
}
export default DialogOpenOtp;
