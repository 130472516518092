import { useContext } from 'react'
import AppContext from "../../context/appContext";
import { raiseComplaint } from '../services/DisputeService';
import * as moment from "moment";

export const InitiateDisputeHelper = () => {
    const { disputeData, setDisputeData } = useContext(AppContext);

    async function raiseComplaintWithIssuer({transactionComplaintRequest, openPlsWaitDialog, closePlsWaitDialog,
        openIsSysDownDialog, setPristine, formik, openSessionExpiredDialog}) {

        openPlsWaitDialog();
        try {
            const res = await raiseComplaint(transactionComplaintRequest);

            if (res !== null && (res.status === 201 || res.status === 200)) {
                closePlsWaitDialog();
                disputeData.complaintId = res.data.complaintId;
                disputeData.creationDate = moment(new Date(res.data.creationDate)).format('DD-MM-YYYY');
                setDisputeData(disputeData);
                if (disputeData.complaintId !== '') {
                    setPristine();
                    window.location.hash = '/disputesuccess';
                } else {
                    formik.handleReset();
                }
            } else if (res.status !== 201 && res.status !== 200) {
                closePlsWaitDialog();
                openIsSysDownDialog();
            }
        } catch (error) {
            closePlsWaitDialog();
            if(error?.response?.status === 401) {
                openSessionExpiredDialog();
            } else {
                openIsSysDownDialog();
            }
        }
    }

    return { raiseComplaintWithIssuer }
}
