import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'

const useWarning = (message = "Are you sure want to discard changes?") => {
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {

        window.onbeforeunload = isDirty && (() => message);
        window.onpopstate = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
            window.onpopstate = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty])

    const routerPrompt = <Prompt when={isDirty} message={message} />;

    return [routerPrompt, () => setDirty(true), () => setDirty(false)]
}

export default useWarning;
