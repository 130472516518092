import {cardDetail} from '../shared/CardDetail';
import {useDispatch} from 'react-redux';
import {verifyCardholder} from '../services/Auth';

export const useVerifyCard = () => {

    const dispatch = useDispatch();

    const getRequestObject = (values) => {
        const verifyCardRequest = cardDetail;
        verifyCardRequest.firstName = values.firstName;
        verifyCardRequest.lastName = values.lastName;
        verifyCardRequest.mobileNum = values.mobileNum.replace(/[-\s]/g, "");
        if (values.emailID !== '' && values.emailID !== 'undefined') {
            verifyCardRequest.emailID = values.emailID;
        } else {
            verifyCardRequest.emailID = null;
        }
        verifyCardRequest.countryCode = values.countryCode;
        verifyCardRequest.bankCode = values.bankCode;
        if (values.cardNum !== '' && values.cardNum !== 'undefined') {
            verifyCardRequest.cardNum = values.cardNum;
        } else {
            verifyCardRequest.cardNum = null;
        }
        verifyCardRequest.acceptTerms = values.acceptTerms;

        return verifyCardRequest;
    }


    async function verifyCardDetails({
                                         verifyCardDetailsRequest, openplsWait,
                                         openissSysDown, closeplsWait, openOTPDialog, openinvDetail, openaccountLock
                                     }) {
        try {
            openplsWait();
            const res = await verifyCardholder(verifyCardDetailsRequest);

            if (res.data !== null && res.status === 200) {
                closeplsWait();
                if (res.data.response === "Success") {
                    dispatch({type: 'ADD_SESSION', payload: res.headers['session-id']});
                    openOTPDialog();
                }
            }
            return res.data
        } catch (err) {
            closeplsWait();
            if (err.response.status === 400) {
                openinvDetail();
            } else if (err.response.status === 401) {
                openOTPDialog();
            } else if (err.response.status === 403) {
                openaccountLock();
            } else {
                openissSysDown();
            }
        }
        return null;
    }

    return {getRequestObject, verifyCardDetails}
}