import React, { useCallback, useMemo, useReducer} from "react";
import AppContext from "./appContext";
import AppReducer from "./appReducer";

const initialState = {
  disputeData: {},
};
const AppState = (props) => {

  const [state, dispatch] = useReducer(AppReducer, initialState);

    const setDisputeData = useCallback((value) => {
        dispatch({
            type: "SET_DISPUETEDATA",
            payload: value,
        });
    }, []);

    const contextValue = useMemo(() => ({
        state, disputeData:
        state.disputeData, setDisputeData
    }), [state, setDisputeData]);

    return (
        <AppContext.Provider
            value={contextValue}
        >
            {props.children}
        </AppContext.Provider>
    );
}
export default AppState;

