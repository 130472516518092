// For Sonar Fixing issue : Refactor this function to reduce its Cognitive Complexity to the 15 allowed.
export const BankCodeFormik = () => {
    const getBankCode=(props)=>
    {
        return (
            (e, newvalue) => {
        if (newvalue !== null) {
            props.setBankTxt(newvalue);
            props.formik.values.bankCode = newvalue.code;
        } else {
            props.formik.values.bankCode = "";
            props.setBankTxt('');
        }

    }
        );
    }
    return {getBankCode}
}