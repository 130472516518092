import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Select, MenuItem, Grid, Box, TextField, Button, FormControl, Checkbox, FormHelperText, makeStyles } from '@material-ui/core';
import { ErrorMessage, FormikProvider } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PasswordEyeIcon} from "./PasswordIcon/PasswordEyeIcon";
import {BankCodeFormik} from "./PasswordIcon/BankCodeFormik";
import {AcceptTermsHelperTextUserDetails} from "./PasswordIcon/AcceptTermsHelperTextUserDetails";
import {AcceptTermsErrorUserDetails} from "./PasswordIcon/AcceptTermsErrorUserDetails";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    privacy: {
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
            alignItems: "flex-start"
        }
    }

}));

const isError = (props, propertyName) => {

    return props.formik.touched[propertyName] && Boolean(props.formik.errors[propertyName]);
}

const UserDetail = (props) => {
    const classes = useStyles();
    const disabled = true;
    const {values,getEyeIcon} = PasswordEyeIcon();
    const {getBankCode} = BankCodeFormik();
    const {getAcceptTermHelperTextUserDetails} = AcceptTermsHelperTextUserDetails();
    const {getAcceptTermErrorUserDetails} = AcceptTermsErrorUserDetails();

    return (
        <Box paddingBottom={4} paddingtop={2} paddingLeft={4}>
            <hr className="raiseDisputehr"></hr>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">First Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField label="Required" id="firstName" name="firstName"
                               type="text" variant="outlined" size="small" style={{ width: 245 }}
                               value={props.formik.values.firstName}
                               onChange={props.formik.handleChange}
                               error={isError(props, 'firstName')}
                               helperText={props.formik.touched.firstName && props.formik.errors.firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">Last Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField label="Required" id="lastName" name="lastName"
                               type="text" variant="outlined" size="small" style={{ width: 245 }}
                               value={props.formik.values.lastName}
                               onChange={props.formik.handleChange}
                               error={isError(props, 'lastName')}
                               helperText={props.formik.touched.lastName && props.formik.errors.lastName} />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">Mobile Number</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl>
                        <Select className="mobileSelect" required
                                labelId="countryCode" placeholder="code"
                                id="countryCode" name="countryCode" disabled={disabled}
                                value={props.formik.values.countryCode} size="small"
                                onChange={props.formik.handleChange} variant="outlined"
                                error={isError(props, 'countryCode')}
                                helperText={props.formik.touched.countryCode && props.formik.errors.countryCode} >
                            <MenuItem value={'+91'}>+91</MenuItem>
                        </Select>
                        <FormHelperText name="bankNameHelperText"
                                        style={{ display: props.codeErrDisplay, color: 'red' }}>
                            Please Select Country Code</FormHelperText>
                    </FormControl>
                    <TextField label="Required" id="mobileNum" name="mobileNum"
                               type="text" variant="outlined" size="small"
                               style={{ width: 150 }}
                               value={props.formik.values.mobileNum}
                               onChange={props.formik.handleChange}
                               error={isError(props, 'mobileNum')}
                               helperText={props.formik.touched.mobileNum && props.formik.errors.mobileNum} />
                </Grid>

            </Grid>

            <Grid container spacing={1}>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">Email ID</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField label="Required" id="emailID" name="emailID"
                               type="text" variant="outlined" size="small" style={{ width: 245 }}
                               value={props.formik.values.emailID}
                               onChange={props.formik.handleChange}
                               error={isError(props, 'emailID')}
                               helperText={props.formik.touched.emailID && props.formik.errors.emailID} />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">Bank Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete paddingbottom={14}
                                  id="bankCode" className="bankSelect"
                                  name="bankCode"
                                  value={props.bankTxt}
                                  options={props.bankItems}
                                  getOptionLabel={option => option.description}
                                  onChange={getBankCode(props)}
                                  renderInput={params => (
                                      <TextField {...params} name="bankCode" label="Please Select" size="small"
                                                 variant="outlined" value={props.formik.values.bankCode} className="bankSelect"
                                                 helperText={props.formik.touched.bankCode && props.formik.errors.bankCode}
                                                 error={isError(props, 'bankCode')}
                                      />
                                  )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="raiseDisputeLabel">Card Number</Typography>
                    <Typography className="raiseDisputeLabelBrac">(Only Diners Club International card is accepted)</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField label="Required" id="cardNum" name="cardNum" placeholder =""
                               variant="outlined" size="small" style={{ width: 245 }}
                               type={values.showPassword ? 'text' : 'password'}
                               value={props.formik.values.cardNum}
                               onChange={(event) => {
                                   if(event.target.value && event.target.value.length > 0) {
                                       const value = event.target.value;
                                       event.target.value = value.replace(/\s/g, '')
                                   }
                                   props.formik.handleChange(event);
                               }}
                               error={isError(props, 'cardNum')}
                               helperText={props.formik.touched.cardNum && props.formik.errors.cardNum}
                               InputProps={getEyeIcon()}/>
                </Grid>
            </Grid>
            <FormikProvider value={props.formik}>
                <Grid container className={classes.privacy} style={{ height: "80px" }} >
                    <Grid item  >
                        <Checkbox id="acceptTerms" name="acceptTerms" size="small"
                                  checked={props.formik.values.acceptTerms}
                                  value={props.formik.values.acceptTerms} onChange={props.formik.handleChange}
                                  error={getAcceptTermErrorUserDetails(props)}
                                  helperText={getAcceptTermHelperTextUserDetails(props)}
                                  style={{ color: "#1d80c2" }}
                        />
                    </Grid>
                    <Grid item >
                        {/* <Link onClick={() => alert()}><Typography style={{ "font-size": "13px" }}>Accept Terms & Conditions</Typography></Link> */}
                        <Button variant="text" className="tnCButton" name="newButton" style={{ "padding": "6px 0px" }}
                                onClick={() => props.openTerms()}>Accept Privacy Policy and Terms & Conditions</Button>
                    </Grid>
                    <Grid container justifyContent="center">
                        <ErrorMessage name="acceptTerms" component="span" style={{
                            "color": "red", "font-size": "0.75rem",
                            "font-family": "Roboto, Helvetica, Arial, sans-serif"
                        }} />
                    </Grid>
                </Grid>
            </FormikProvider>

            <Grid align="center" display={props.formik.values.buttondisplay}>
                <div >
                    <div>
                        {/* <Button onClick={handleClickOpen}>OTP</Button> */}
                        <Button variant="contained" className="raiseDisputeButton" type="submit">
                            Submit
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="contained" className="raiseDisputeButton" component={Link} to="/">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Grid>
        </Box>
    )
}
export default UserDetail;