import api from './Api';
import { serviceEndpoints } from './ServiceEndpoints';

export const raiseComplaint = async payload => {
    return api().post(serviceEndpoints.raiseComplaint, payload);
};

export const complaintEnquiry = async payload => {
    return api().get(`${serviceEndpoints.complaintEnquiry}/${payload}`);
};
