import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Introduction = (props) => {

   return (
      <>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>We respect the value of privacy of any Information you provide on the
        Online Dispute Resolution Portal available at <a href="https://www.dinersclub.com/">DinersClub.com</a> to Diners Club International Limited
             (“<Box fontWeight='fontWeightMedium' display='inline'>Diners Club</Box>”) and/or its affiliates and partners.
                     Diners Club is fully committed to respect and protect your privacy through its compliance with this privacy policy
                     (“<Box fontWeight='fontWeightMedium' display='inline'>Privacy Policy</Box>”).
                     <a href="https://www.dinersclub.com/privacy-policy/">Privacy Policy</a> </Typography>

      </>
   )

}
export default Introduction;
