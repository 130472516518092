import React from 'react';
import { Typography } from '@material-ui/core';
import Introduction from './Introduction';
import TermsOfUse from './TermsOfUse';

const PrivacyPolicy = (props) => {
    return (
        <>
            <Introduction />
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>If you have any queries relating to
            the processing/usage of Information provided by you or Privacy Policy, you may contact us at <a href="mailto:Privacy@discover.com">Privacy@discover.com</a>.
            </Typography>
            <p />
            <TermsOfUse />
        </>
    )

}
export default PrivacyPolicy;
