import React from 'react';
import { Grid, Button, Dialog, DialogContent, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ColoredLine from './ColoredLine';
import PrivacyPolicy from './content/PrivacyPolicy';


const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        position: 'absolute',
        top: theme.spacing(3),
        bottom: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
        fontWeight: 'bold',
        color: "brown"
    }
}))

const DialogTermsAndConditions = (props) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
            <DialogContent >
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h6" component="div" align="center" className={classes.title}> PRIVACY POLICY</Typography>
                        </div>
                        <PrivacyPolicy />
                    </Grid>

                </Grid>
            </DialogContent>
            <Grid align="center" style={{ paddingBottom: '10px' }}>
                <ColoredLine color="grey" />
                <Button onClick={props.close} variant="outlined" className="sucessPopupButton">OK</Button>
            </Grid>
        </Dialog >
    )
}
export default DialogTermsAndConditions;
