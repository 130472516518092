import React from 'react'

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            //  backgroundColor: color,
            height: 0,

        }}
    />
);
export default ColoredLine;
