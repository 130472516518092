import React from 'react';
import { Grid, Box, TextField, Button, Typography, FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import TransactionCurrency from './TransactionCurrency';
import FailedTransactionReason from './FailedTransactionReason';
import { Link } from 'react-router-dom';

const TransactionDetail = (props) => {
    const disabled = true;

    return (
        <>
            <Box paddingbottom={4} paddingTop={2} paddingLeft={4}>
                <hr className="raiseDisputehr"></hr>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="raiseDisputeLabel">First Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField id="firstName" name="firstName" disabled={disabled}
                            variant="outlined" size="small" className="disabledText"
                            value={props.formik.values.firstName} />


                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="raiseDisputeLabel">Last Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField id="lastName" name="lastName" disabled={disabled}
                            variant="outlined" size="small" className="disabledText"
                            value={props.formik.values.lastName} />
                    </Grid>
                </Grid><p></p>
            </Box>

            <Box id="tranBox" name="tranBox"
                paddingbottom={4} paddingTop={2} paddingLeft={4}>
                <Typography
                    className="tranHeader">TRANSACTION DETAILS</Typography>
                <hr className="raiseDisputehr" ></hr>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="raiseDisputeLabel">Merchant Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField label="Required" id="merchantName" name="merchantName"
                            type="text" variant="outlined" size="small"
                            style={{ width: "225px" }}
                            value={props.formik.values.merchantName}
                            onChange={props.handleOnchange}
                            error={props.formik.touched.merchantName && Boolean(props.formik.errors.merchantName)}
                            helperText={props.formik.touched.merchantName && props.formik.errors.merchantName} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="raiseDisputeLabel">Transaction Date</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Required"
                                InputLabelProps={{
                                    shrink: props.selectedDate ? true : undefined,
                                }}
                                className="commonWidthArea"
                                id="chargeDate" name="chargeDate"
                                value={props.selectedDate}
                                onChange={props.handleDateChange}
                                format="DD/MM/YYYY" maxDate={new Date()}
                                size="small"
                                InputProps={{ readOnly: true }}
                                inputVariant="outlined"
                                error={props.formik.touched.chargeDate && Boolean(props.formik.errors.chargeDate)}
                                helperText={props.formik.touched.chargeDate && props.formik.errors.chargeDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <p ></p>
                    <TransactionCurrency currencyTxt={props.currencyTxt} currencyItems={props.currencyItems}
                        setDirty={props.setDirty} formik={props.formik} setCurrencyTxt={props.setCurrencyTxt}
                    />

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="raiseDisputeLabel">Transaction Amount</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField label="Required" id="chargeAmount" name="chargeAmount"
                            variant="outlined" size="small" className="commonWidthArea"
                            value={props.formik.values.chargeAmount}
                            onChange={props.handleOnchange}
                            error={props.formik.touched.chargeAmount && Boolean(props.formik.errors.chargeAmount)}
                            helperText={props.formik.touched.chargeAmount && props.formik.errors.chargeAmount} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="optionalLabel">Dispute Amount</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl>
                            <TextField id="disputeAmount" name="disputeAmount"
                                variant="outlined" size="small" className="commonWidthArea"
                                value={props.formik.values.disputeAmount}
                                onChange={props.handleOnchange}
                                error={props.formik.touched.disputeAmount && Boolean(props.formik.errors.disputeAmount)}
                                helperText={props.formik.touched.disputeAmount && props.formik.errors.disputeAmount} />
                            <FormHelperText name="dispAmtHelperText"
                                style={{ display: props.dispAmtErrDisplay, color: 'red' }}>
                                Dispute Amount should have 4 or less digits after decimal</FormHelperText>
                        </FormControl>
                    </Grid>

                    <FailedTransactionReason formik={props.formik} reasonTxt={props.reasonTxt} reasonItems={props.reasonItems}
                        setDirty={props.setDirty} setReasonTxt={props.setReasonTxt} />

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className={props.formik.values.failedTransactionReason !== "OTHR" ? "optionalLabel" : "furtherTextLabel"}>Further details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField id="furtherDetails" multiline rows={4} className="commonWidthArea"
                            variant="outlined" name="furtherDetails" size="small"
                            value={props.formik.values.furtherDetails}
                            onChange={props.handleOnchange}
                            error={props.formik.touched.furtherDetails && Boolean(props.formik.errors.furtherDetails)}
                            helperText={props.formik.touched.furtherDetails && props.formik.errors.furtherDetails} />
                    </Grid>
                </Grid><p></p>
                <div align="center">
                    <Button variant="contained" className="raiseDisputeButton" type="submit" >
                        Submit
                            </Button>&nbsp;&nbsp;
                    <Button variant="contained" className="raiseDisputeButton" component={Link} to="/">Cancel</Button>
                    <hr className="raiseDisputehr"></hr>
                </div>
            </Box>
        </>
    )

}
export default TransactionDetail;
