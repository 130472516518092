import React, { Component } from 'react';
import './App.css';
import AppState from './context/AppState';
import { Container } from '@material-ui/core';
import AuthenticateUser from './Components/auth/AuthenticateUser';
import InitiateDispute from './Components/dispute/InitiateDispute';
import HomePage from './Components/home/HomePage';
import { Switch, Route, HashRouter } from "react-router-dom";
import DisputeSuccess from './Components/dispute/DisputeSuccess';
import DisputeStatus from './Components/enquiry/DisputeStatus';
import DisputeStatusEnquiry from './Components/enquiry/DisputeStatusEnquiry';
import Header from './Components/header/Header';
import Footer from './Components/footer/Footer';
import AuthenticatedRoute from './Components/AuthenticatedRoute';
import {remove} from './Components/services/SessionService';
import AccessDenied from './Components/bot/AccessDenied';

const RenderInitiateDispute = (props) => (<InitiateDispute {...props} />);
const RenderDisputeSuccess = (props) => (<DisputeSuccess {...props} />);
const RenderDisputeStatus = (props) => (<DisputeStatus {...props} />);
const RenderDisputeStatusEnquiry = (props) => (<DisputeStatusEnquiry {...props} />);

class App extends Component {

  componentDidMount() {
    window.addEventListener("beforeunload", this.unload);
  }

  unload = (e) => {
    remove();
  }


  render() {

    return (
      <AppState>
        <HashRouter basename="/">
          <Container maxWidth="xl" className="App" >
            <Header />
            <Switch>
              <Route path='/raisedispute/:action'
                exact
                render={(props) => (
                  <AuthenticateUser {...props} />
                )}>
              </Route>
              <AuthenticatedRoute path="/raisedisputetrandetails"
                component={RenderInitiateDispute}
              />
              <AuthenticatedRoute path="/disputesuccess"
                component={RenderDisputeSuccess}
              />
              <AuthenticatedRoute path="/disputestatus"
                component={RenderDisputeStatus}
              />
              <AuthenticatedRoute path="/disputeEnquiry"
                component={RenderDisputeStatusEnquiry}
              />
              <Route path='/accessDenied'

                render={(props) => (
                  <AccessDenied {...props} />
                )} />
              <Route path='/'
                render={(props) => (
                  <HomePage {...props} />
                )}>
              </Route>


            </Switch>
            <Footer />
          </Container>
        </HashRouter>
      </AppState>
    );
  }
}

export default App;
