import React from 'react';
import { Grid, Box, Button, Dialog, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ColoredLine from './ColoredLine';
import { Link } from 'react-router-dom';

const DialogOpenSessionExpired = (props) => {
    return (
        <Dialog open={props.open}>
                <Box className="successPopup">
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <Typography className="successPopupHeader">Session expired</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <DialogContent>

                    <Grid container spacing={1} style={{ paddingBottom: '25px', paddingTop: '15px' }}>
                        <Grid item xs={12}>
                            <p>Your session has expired, please try again</p>
                        </Grid>
                    </Grid>




                </DialogContent>
                <Grid align="center" style={{ paddingBottom: '10px' }}>
                    <ColoredLine color="grey" />
                    <Button variant="outlined" className="sucessPopupButton" component={Link} to="/">Close</Button>

                </Grid>
        </Dialog>
    )
}
export default DialogOpenSessionExpired;
