import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TransactionCurrency = (props) => {
    return (
        <>
            <Grid item xs={12} sm={6} md={3}>
                <Typography className="raiseDisputeLabel">Transaction Currency</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Autocomplete paddingbottom={14}
                    id="currencyCode" className="currencySelect"
                    name="currencyCode" size="small"
                    value={props.currencyTxt}
                    options={props.currencyItems}
                    getOptionLabel={option => option.name}
                    onChange={(e, newValue) => {
                        if (newValue !== null) {
                            props.setDirty();
                            props.formik.values.currencyCode = newValue.name;
                            props.setCurrencyTxt(newValue);
                            props.formik.validateField('currencyCode');
                        } else {
                            props.formik.values.currencyCode = "";
                            props.setCurrencyTxt('');
                        }
                    }}
                    renderInput={params => (
                        <TextField {...params} name="currencyCode" label="Please Select" size="small"
                            variant="outlined" value={props.formik.values.currencyCode} className="currencySelect"
                            helperText={props.formik.touched.currencyCode && props.formik.errors.currencyCode}
                            error={Boolean(props.formik.touched.currencyCode && props.formik.errors.currencyCode)}
                        />
                    )}
                />
            </Grid>
        </>
    )
}
export default TransactionCurrency;
