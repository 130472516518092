import { useContext } from 'react'
import { useDispatch } from 'react-redux';
import { validateOtp } from '../services/Auth';
import {
    registerSuccess,
} from '../services/SessionService';
import AppContext from "../../context/appContext";
import { useNavigate } from './useNavigate';


export const useAuthHelper = () => {
    const dispatch = useDispatch();
    const { setDisputeData } = useContext(AppContext);
    const { navigate } = useNavigate();


    async function validateOTP({ otpvalidate, resetForm, openplsWait, setOpenOTPPopup,
        formik, closeplsWait, setinvalidOtpEntered, openOTPDialog, openissSysDown, openSessionExpiredDialog}) {
        try {
            openplsWait();
            setOpenOTPPopup(false);
            const res = await validateOtp(otpvalidate);

            if (res.data !== null && res.status === 200) {
                resetForm();
                if (res.data.response === "Success") {

                    registerSuccess(true);
                    dispatch({ type: 'AUTHENTICATE', payload: true });
                    const disputeData = {
                        actionName: formik.values.actionName,
                        firstName: formik.values.firstName,
                        lastName: formik.values.lastName,
                        bankCode: formik.values.bankCode,
                        mobileNum: formik.values.mobileNum,
                        cardNum: formik.values.cardNum,
                        countryCode: formik.values.countryCode
                    };

                    setDisputeData(disputeData);
                    navigate({ formik });

                }
                if (res.data.response === "Failed") {
                    if (res.data.errorMessage === "INVOTP") {
                        closeplsWait();
                        setinvalidOtpEntered(true);
                        openOTPDialog();
                    } else {
                        setinvalidOtpEntered(false);
                        closeplsWait();
                        openissSysDown();
                    }
                }

            }
            return res.data
        }
        catch (err) {
            closeplsWait();
            resetForm();
            if(err?.response?.status === 401) {
                openSessionExpiredDialog();
            } else {
                openissSysDown();
            }
        }
        return null;
    }

    return { validateOTP }

}
