export const registerSuccess = (value) => {
    sessionStorage.setItem('isValid', value);
}

export const remove = () => {
    sessionStorage.removeItem('isValid');
}

export const isValid = () => {

    const value = sessionStorage.getItem('isValid');
    return value !==null;
}




