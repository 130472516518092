// For Sonar Fixing issue : Refactor this function to reduce its Cognitive Complexity to the 15 allowed.
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export const PasswordEyeIcon = () => {

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getEyeIcon=()=>
    {
        return (
            {
                endAdornment:<InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showPassword? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>}
        );
    }
    return {values,getEyeIcon}
}