import React from 'react';
import { Grid, Box, Button, Dialog, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ColoredLine from './ColoredLine';

const DialogAccountLock= (props) => {
    return (
        <Dialog open={props.open}>
            <Box className="successPopup">
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <Typography className="successPopupHeader">Something went wrong....</Typography>
                    </Grid>
                </Grid>
            </Box>
            <DialogContent>
                    <Grid container spacing={1} style={{ paddingBottom: '50px', paddingTop: '25px' }}>
                    <Grid item xs={12}>                            
                            <Typography>Please try again later or contact your bank for clarification. </Typography>
                            <p></p>
                        </Grid>
                    </Grid>
           </DialogContent>           
            <Grid align="center" style={{ paddingBottom: '10px' }}>
                <ColoredLine color="grey" />
                <Button onClick={props.close} variant="outlined" className="sucessPopupButton">OK</Button>
            </Grid>
        </Dialog>       
    )
}
export default DialogAccountLock