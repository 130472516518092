import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { Grid, Box, Button, Card, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AppContext from "../../context/appContext";
import CicleIcon from '@material-ui/icons/Brightness1Outlined';
import '../component.css';
import {
  withRouter
} from "react-router-dom";

const validationSchema = yup.object({

});

const useStyles = makeStyles((theme) => ({

  homepageCard: {
    "background-image": "url('../bg-content-background.png')",
    height: "80vh !important",
    "background-repeat": "no-repeat",
    "background-position": "right bottom",

  },
  homePageheader: {
    color: "brown",
    "font-weight": "bold !important",
    "font-size": "x-large !important",
    "padding-left": "280px",
    [theme.breakpoints.down("sm")]: {
      "padding-left": "20px",
      "font-size": "large !important"

    }
  },
  homePagehr: {
    color: "brown",
    width: "1200px",
    height: "2px",
    "align-self": "baseline",
    "background-color": "brown",
    "margin-left": "280px !important",
    [theme.breakpoints.down("sm")]: {
      "margin-left": "20px !important",
      height: "1px",

    }
  },
  extpaddingLeft: {
    "padding-left": "280px !important",
    [theme.breakpoints.down("sm")]: {
      "padding-left": "20px !important"
    }

  }

}))

const HomePage = () => {
  const classes = useStyles();
  const { setDisputeData } = useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      actionName: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const disputeData = {
        actionName: formik.values.actionName
      };

      setDisputeData(disputeData);
      if (formik.values.actionName === "new") {
        window.location.hash = '/raisedispute/new';
      }
      if (formik.values.actionName === "existing") {
        window.location.hash = '/raisedispute/existing';
      }
    }
  });

  return (
    <div>
      <Card className="homePageCard">
        <form id="homepageForm" onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
          <Box >
            <p></p>
            <Typography align="left" className={classes.homePageheader}>
              Welcome to Online Dispute Resolution (ODR)  </Typography>
            <hr className={classes.homePagehr}></hr>
            <p></p>
            <Typography className={classes.extpaddingLeft} >What would you like to do today ?</Typography>
            <Grid container spacing={1} className={classes.extpaddingLeft}>
              <Grid item xs={12}>
                <CicleIcon className="homePageIcon" ></CicleIcon>
                <Button variant="text" className="homePageButton" name="newButton"
                  onClick={() => {
                    formik.values.actionName = "new";
                    formik.handleSubmit();
                  }}>
                  Initiate new Dispute
            </Button ></Grid></Grid>
            <Grid container spacing={1} className={classes.extpaddingLeft}>
              <Grid item xs={12}>
                <CicleIcon className="homePageIcon" ></CicleIcon>
                <Button variant="text" className="homePageButton"
                  name="existingButton"
                  onClick={() => {
                    formik.values.actionName = "existing";
                    formik.handleSubmit();
                  }}>
                  Track my Dispute/s
            </Button >
              </Grid>
            </Grid>
          </Box>


        </form>
      </Card>

    </div>
  );
}

export default withRouter(HomePage);
