import React, { useContext } from 'react';
import { Card, Typography, Box, makeStyles, TableBody, TableHead, TableRow, Table, TableCell } from '@material-ui/core';
import AppContext from "../../context/appContext";
import {
      withRouter
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
      boxMain: {
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(18),
            [theme.breakpoints.down("sm")]: {
                  paddingLeft: theme.spacing(5),
            }
      }

}))

const DisputeStatus = () => {
      const classes = useStyles();

      const { disputeData } = useContext(AppContext);

      return (

            <Card className="homePageCard">
                  <p></p>
                  <Box borderColor="primary.main"
                        className={classes.boxMain}>
                        <Typography style={{ color: 'brown', fontWeight: 'bold', fontSize: '14px' }}>
                              COMPLAINT STATUS ENQUIRY</Typography>
                        <hr className="raiseDisputehr"></hr>
                        <p></p>

                        <Table className="tableStyle">
                              <TableHead>
                                    <TableRow>
                                          <TableCell className="headercell">
                                                <Typography className="tableHeader">
                                                      Diners Club international Reference Number</Typography>
                                          </TableCell>
                                          <TableCell className="headercell">
                                                <Typography className="tableHeader">Status</Typography>
                                          </TableCell>
                                    </TableRow>
                              </TableHead>
                              <TableBody>
                                    <TableRow>
                                          <TableCell className="tableCell">
                                                <Typography align="center" style={{ fontSize: '13px' }}>{disputeData.complaintId}</Typography>
                                          </TableCell>
                                          <TableCell className="tableCell">
                                                <Typography align="center" style={{ fontSize: '13px' }}>{disputeData.complaintStatus}</Typography>
                                          </TableCell>
                                    </TableRow>
                              </TableBody>
                        </Table>
                  </Box>
            </Card>


      );
}
export default withRouter(DisputeStatus);
