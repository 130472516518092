import * as yup from 'yup';

export const validationSchema = yup.object({

    merchantName: yup
        .string('Enter Merchant Name')
        .required('Merchant Name is required')
        .max(36, 'Merchant name should not exceed 36 characters')
        .matches(/^[A-Za-z0-9 ]*$/, 'Please enter valid merchant name'),

    chargeDate: yup
        //.string()
        .date('Enter Transaction date')
        .required('Transaction date is required')
        //.matches("/^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/","asfaasg")
        .max(new Date(), "Transaction date cannot be future date"),

    currencyCode: yup
        .string('Enter Transaction Currency')
        .required('Transaction Currency is required')
        .min(3, 'Please enter valid Transaction Currency')
        .max(3, 'Transaction Currency should not exceed 3 digits'),

    chargeAmount: yup
        .string("Enter Transaction Amount")
        .required('Transaction Amount is required')
        .notOneOf(['0.0', '0'], "Transaction Amount should be greater than 0")
        .matches(/^\d{1,12}(\.\d{1,4})?$/, "Please enter valid Transaction Amount"),

    disputeAmount: yup
        .string("Enter Dispute Amount")
        .notRequired()
        .notOneOf(['0.0', '0'], "Dispute Amount should be greater than 0")
        .matches(/^\d{1,12}(\.\d{1,4})?$/, "Please enter valid Dispute Amount")
        .test({
            name: 'max',
            exclusive: false,
            params: {},
            message: 'Dispute Amount cannot be greater than Transaction Amount',
            test: function (value) {

                return value != null ? parseFloat(value) <= parseFloat(this.parent.chargeAmount) : true;
            },
        }),

    failedTransactionReason: yup
        .string('Enter Failed Transaction Reason')
        .required('Failed Transaction Reason is required'),

    furtherDetails: yup
        .string('Enter furtherDetails')
        .max(1024, 'Further Details should not exceed 1024 characters')
        //.matches(/^[A-Za-z0-9 ]*$/, 'Please enter valid details')
        .when('failedTransactionReason', {
            is: "OTHR",
            then: yup.string().required('Further Details is required'),
        }),
});
