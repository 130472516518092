import React from 'react'
import { Grid, Card, makeStyles, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        color: "white",
        "font-size": "1.95em",
        "margin-left": "4%",
        "vertical-align": "top",
        [theme.breakpoints.down("xs")]: {
            "font-size": "1.1em",
            "margin-left": theme.spacing(0),

        }
    },
    logo: {
        width: "90%",
        [theme.breakpoints.down("sm")]: {
            width: "90%"
        }
    },
    home: {
        [theme.breakpoints.down("sm")]: {

            fontSize: 13
        }
    },
    homeIcon: {
        height: "23px !important",
        width: "25px !important",
        color: "#1973b1!important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0.5),
            height: "18px !important",
            width: "18px !important",
        }
    }

}))

const Header = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container justifyContent="flex-start" >
                <Grid item xs={4} sm={3} md={2}>
                    <a href="https://www.dinersclub.com" ><img src="/dci_logo_transparent.png" alt="dci-logo" className={classes.logo} ></img></a>
                </Grid>
                <Grid item xs={8} sm={9} md={10}>
                    {/* <label className='AppHeader'>Online Dispute Resolution</label> */}
                    <Typography className={classes.title} >Online Dispute Resolution</Typography>
                </Grid>
            </Grid>
            {/* <label className="AppNote">This application supports Google Chrome and Microsoft Edge only !</label>            */}

            <Card >
                <Grid container >
                    <Grid item >
                        <HomeIcon className={classes.homeIcon} size="small"></HomeIcon>
                    </Grid>
                    <Grid item >
                        <Link to="/" className={classes.home} style={{ textDecoration: 'none' }}>Home</Link>
                    </Grid>
                </Grid>

            </Card>

        </>
    )
}
export default Header;
