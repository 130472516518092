import React, { useContext } from 'react';
import { Grid, Box, Card, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import '../component.css';
import AppContext from "../../context/appContext";
import moment from 'moment';

function DisputeSuccess() {
      const { disputeData } = useContext(AppContext);
      const formik = useFormik({
            initialValues: {
                  merchantName: disputeData.merchantName,
                  creationDate: disputeData.creationDate,
                  complaintId: disputeData.complaintId,
                  chargeDate: disputeData.chargeDate,
                  chargeAmount: disputeData.chargeAmount,
                  failedTransactionReason: disputeData.failedTransactionReason.description,
                  disputeAmount: disputeData.disputeAmount,
                  furtherDetails: disputeData.furtherDetails,
                  currencyCode: disputeData.currencyCode
            },

      });
      return (
            <div>
                  <Card className="raiseDisputeCard">

                        <form id="disputeSuccessForm">

                              <Box paddingBottom={0} paddingTop={2} paddingLeft={4}>
                                    <Typography
                                          className="tranHeader">Submission Confirmation</Typography>
                                    <hr className="raiseDisputehr"></hr><p></p>
                                    <Typography>
                                          Thank you for your submission. Your Diners Club International Reference Number is : {formik.values.complaintId}
                                    </Typography><p></p>
                                    <Typography>
                                          Please retain this information for future reference and to track your case status.
                                    </Typography><p></p>
                                    <hr className="raiseDisputehr" ></hr>
                              </Box>

                              <Box id="tranBox" name="tranBox"
                                    paddingBottom={3} paddingTop={3} paddingLeft={4}>

                                    <Grid container>
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Merchant Name</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography>: {formik.values.merchantName}</Typography>
                                          </Grid>
                                    </Grid>
                                    <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Transaction Date (DD/MM/YYYY)</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography>: {moment(new Date(formik.values.chargeDate)).format('DD-MM-YYYY')}</Typography>
                                          </Grid>
                                    </Grid>
                                    <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Date case created (DD/MM/YYYY)</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography>: {formik.values.creationDate}</Typography>
                                          </Grid>
                                    </Grid>
                                    <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Transaction amount</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography>: {formik.values.currencyCode} {formik.values.chargeAmount}</Typography>
                                          </Grid>
                                    </Grid>
                                    {formik.values.disputeAmount ? <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Dispute amount</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography>: {formik.values.currencyCode} {formik.values.disputeAmount}</Typography>
                                          </Grid>
                                    </Grid> : null}
                                    <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Failed transaction Reason</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography className="dissuccessLabel">: {formik.values.failedTransactionReason}</Typography>
                                          </Grid>
                                    </Grid>
                                    {formik.values.furtherDetails ? <Grid container className="disputeSuccessGrid">
                                          <Grid item xs={6} sm={2}>
                                                <Typography className="raiseDisputeLabel">Further Detail</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={10}>
                                                <Typography className="dissuccessLabel">: {formik.values.furtherDetails}</Typography>
                                          </Grid>
                                    </Grid> : null}
                                    <p style={{ height: 10 }}></p>
                                    <hr className="raiseDisputehr"></hr>
                              </Box>
                        </form>
                  </Card>
            </div>

      );
}

export default DisputeSuccess;
