
export const useNavigate = () => {

    const navigate = ({ formik }) => {

        if (formik.values.actionName === "new") {
            setTimeout(() =>
                window.location.hash = '/raisedisputetrandetails', 0);
        }
        if (formik.values.actionName === "existing") {
            setTimeout(() =>
                window.location.hash = '/disputeEnquiry', 0);
        }

    }

    return { navigate };

}
