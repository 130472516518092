import api from './Api';
import { serviceEndpoints } from './ServiceEndpoints';

export const verifyCardholder = async payload => {
    return api().post(serviceEndpoints.verifyCardholder, payload);
};

export const validateOtp = async payload => {
    return api().post(serviceEndpoints.validateOtp, payload);
};








