import React from 'react';
import { Card } from '@material-ui/core';
import { useFormik } from 'formik';
import '../component.css';
import { withRouter } from "react-router-dom";
import DialogPleaseWait from '../dialogs/DialogPleaseWait';
import DialogOpenOtp from '../dialogs/DialogOpenOtp';
import SessionExpiredPopup from '../dialogs/SessionExpiredPopup';
import DialogSystemDown from '../dialogs/DialogSystemDown';
import { validationSchema } from '../validation/VerifyInfoValidationSchema';
import { otpValidationSchema } from '../validation/OtpValidationSchema';
import DialogInvalidDetail from '../dialogs/DialogInvalidDetail';
import DialogAccountLock from '../dialogs/DialogAccountLock';
import DialogTermsAndConditions from '../dialogs/DialogTermsAndConditions';
import HomePage from '../home/HomePage';
import { useAuthHelper } from './useAuthHelper';
import { useVerifyCard } from './useVerifyCard'
import UserDetail from './UserDetail';


const AuthenticateUser = (props) => {
  const { validateOTP } = useAuthHelper();
  const { getRequestObject, verifyCardDetails } = useVerifyCard();
  const [openOTPPopup, setOpenOTPPopup] = React.useState(false);
  const [openSessionExpiredPopup, setSessionExpiredPopup] = React.useState(false);
  const [plsWait, setplsWait] = React.useState(false);
  const [openInvalidDetail, setOpenInvalidDetail] = React.useState(false);
  const [openIssSysDown, setOpenIssSysDown] = React.useState(false);
  const [openAccountLock, setOpenAccountLock] = React.useState(false);
  const [bankTxt, setBankTxt] = React.useState('none');
  const [codeErrDisplay, setCodeErrDisplay] = React.useState('none');
  const [invalidOtpEntered, setinvalidOtpEntered] = React.useState(false);
  const [bankItems] = React.useState([
    { description: 'HDFC Bank', code: '0001' }
  ]);
  const [termsAndCondition, setTermsAndCondition] = React.useState(false);

  const openplsWait = () => setplsWait(true);
  const closeplsWait = () => setTimeout(() => setplsWait(false), 2000);


  const openinvDetail = () => setTimeout(() => setOpenInvalidDetail(true), 2000);
  const closeinvDetail = () => setOpenInvalidDetail(false);

  const openissSysDown = () => setTimeout(() => setOpenIssSysDown(true), 2000);
  const closeissSysDown = () => setOpenIssSysDown(false);

  const openaccountLock = () => setTimeout(() => setOpenAccountLock(true), 2000);
  const closeaccountLock = () => setOpenAccountLock(false);  

  const openOTPDialog = () => setTimeout(() => setOpenOTPPopup(true), 2000);
  const openSessionExpiredDialog = () => setTimeout(() => setSessionExpiredPopup(true), 2000);

  const openTermsAndCondition = () => setTimeout(() => setTermsAndCondition(true), 0);
  const closeTermsAndCondition = () => setTermsAndCondition(false);

  const otpFormik = useFormik({
    initialValues: {
      otpCode: ''
    },
    validationSchema: otpValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const otpvalidate = {
        otp: values.otpCode
      };
      validateOTP({
        otpvalidate, resetForm, openplsWait, setOpenOTPPopup,
        formik, closeplsWait, setinvalidOtpEntered, openOTPDialog, openissSysDown, openSessionExpiredDialog
      })

    },
    onReset: (values) => {
      formik.values.otpCode = "";
    }
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNum: '',
      emailID: "",
      countryCode: '+91',
      bankCode: '',
      cardNum: '',
      actionName: props.match.params.action,
      acceptTerms: false
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {

      if (formik.values.countryCode === '101') {
        formik.errors.countryCode = true;
        setCodeErrDisplay('block');
      } else {
        setCodeErrDisplay('none');
      }
      const verifyCardDetailsRequest = getRequestObject(values);
      verifyCardDetails({
        verifyCardDetailsRequest, openplsWait,
        openissSysDown, closeplsWait, openOTPDialog, openinvDetail,openaccountLock
      });
    },
    onReset: (values) => {
      setBankTxt('');
      formik.values.bankCode = "";
    }
  });


  // eslint-disable-next-line no-lone-blocks
  {
    if (props.match.params.action === 'new' || props.match.params.action === 'existing') {
      return (

        <div align="left">
          <Card className="raiseDisputeCard">
            <form id="raiseDisputeForm" onSubmit={formik.handleSubmit} onReset={formik.resetForm}>

              <UserDetail formik={formik} openTerms={openTermsAndCondition}
                bankTxt={bankTxt} setBankTxt={setBankTxt} bankItems={bankItems}
                codeErrDisplay={codeErrDisplay} />


              <DialogPleaseWait open={plsWait} />
            </form>
            <DialogInvalidDetail open={openInvalidDetail} close={closeinvDetail} />
            <DialogOpenOtp open={openOTPPopup} otpFormik={otpFormik} invalidOtpEntered={invalidOtpEntered} />
            <SessionExpiredPopup open={openSessionExpiredPopup} />
            <DialogSystemDown open={openIssSysDown} close={closeissSysDown} />
            <DialogAccountLock open={openAccountLock} close={closeaccountLock} />
            <DialogTermsAndConditions open={termsAndCondition} close={closeTermsAndCondition} />
          </Card>
        </div >
      );
    } else {
      return <HomePage />

    }
  }

}

export default withRouter(AuthenticateUser);