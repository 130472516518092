export const cardDetail = {
    "firstName": "",
    "lastName": "",
    "mobileNum": "",
    "emailID": "",
    "countryCode": "",
    "bankCode": "",
    "cardNum": "",
    "acceptTerms": false
}
