import React, { useContext, useState } from 'react';
import { Card } from '@material-ui/core';
import { useFormik } from 'formik';
import * as moment from 'moment';
import '../component.css';
import AppContext from "../../context/appContext";
import { validationSchema } from '../validation/TransDetailValidationSchema';
import { currencyItems } from '../shared/Currency';
import { InitiateDisputeHelper } from './InitiateDisputeHelper';
import DialogSystemDown from '../dialogs/DialogSystemDown';
import SessionExpiredPopup from "../dialogs/SessionExpiredPopup";
import DialogPleaseWait from '../dialogs/DialogPleaseWait';
import useWarning from '../useWarning';
import { transaction } from '../shared/Transaction';
import {

    remove,

} from '../services/SessionService';
import { useDispatch } from 'react-redux';
import TransactionDetail from './TransactionDetail';

import {
    withRouter
} from "react-router-dom";

const InitiateDispute = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            remove();
            dispatch({ type: 'REMOVE_SESSION' });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const { raiseComplaintWithIssuer } = InitiateDisputeHelper();
    const [Prompt, setDirty, setPristine] = useWarning();
    const { disputeData, setDisputeData } = useContext(AppContext);
    const [openIsSysDown, setOpenIsSysDown] = React.useState(false);
    const openIsSysDownDialog = () => setTimeout(() => setOpenIsSysDown(true), 2000);
    const [openSessionExpired, setOpenSessionExpiredDialog] = React.useState(false);
    const openSessionExpiredDialog = () => setTimeout(() => setOpenSessionExpiredDialog(true), 2000);
    const closeIsSysDownDialog = () => setOpenIsSysDown(false);
    const [plsWait, setPlsWait] = React.useState(false);
    const openPlsWaitDialog = () => setPlsWait(true);
    const closePlsWaitDialog = () => setTimeout(() => setPlsWait(false), 2000);
    const [currencyTxt, setCurrencyTxt] = React.useState(null);
    const [reasonTxt, setReasonTxt] = React.useState(null);
    const [dispAmtErrDisplay, setDispAmtErrDisplay] = React.useState('none');

    const [reasonItems] = React.useState([
        { description: 'Disruption of Communication Links', code: 'DOCL' },
        { description: 'Non-availability of cash in ATMs', code: 'NACA' },
        { description: 'Time-out of Sessions', code: 'TOSE' },
        { description: 'Non-credit to Beneficiary’s Account due to Various Cause', code: 'NCBA' },
        { description: 'Other Reason', code: 'OTHR' }
    ]);
    currencyItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
    const formik = useFormik({
        initialValues: {
            firstName: disputeData.firstName,
            lastName: disputeData.lastName,
            bankDetail: disputeData.bankCode,
            mobileNumber: disputeData.mobileNum,
            cardNumberToken: disputeData.cardNum,
            countryCode: disputeData.countryCode,

            merchantName: "",
            chargeDate: "",
            currencyCode: '',
            chargeAmount: '',
            disputeAmount: '',
            failedTransactionReason: '',
            furtherDetails: ""
        },
        validationSchema: validationSchema,

        onSubmit: (values) => {
            formik.values.isTouched = false;
            setPristine();
            if (!formik.errors.disputeAmount) {
                const transactionComplaintRequest = getRequestObject(values);
                disputeData.chargeDate = values.chargeDate;
                disputeData.chargeAmount = values.chargeAmount;
                disputeData.merchantName = values.merchantName;
                disputeData.failedTransactionReason = reasonTxt;
                disputeData.disputeAmount = values.disputeAmount;
                disputeData.furtherDetails = values.furtherDetails;
                disputeData.currencyCode = values.currencyCode;
                setDisputeData(disputeData);
                raiseComplaintWithIssuer({transactionComplaintRequest, openPlsWaitDialog, closePlsWaitDialog,
                    openIsSysDownDialog, setPristine, formik, openSessionExpiredDialog});
            }
        },
        onReset: (values) => {
            setPristine();
            setSelectedDate(null);
            setDispAmtErrDisplay('none');
            formik.values.currencyCode = "";
            setCurrencyTxt('');
            setReasonTxt('');
            formik.values.failedTransactionReason = "";
            formik.values.chargeDate = "";
        }
    });

    const [selectedDate, setSelectedDate] = useState('');
    function handleDateChange(date) {
        setDirty();
        const dateNew = (new Date(date).toLocaleDateString('en-US'));
        setSelectedDate(dateNew);
        formik.values.chargeDate = dateNew;
        formik.validateField('chargeDate');
    }

    function getRequestObject(values) {
        const transactionRequest = transaction;
        transactionRequest.merchantName = values.merchantName;
        transactionRequest.chargeDate = moment(new Date(values.chargeDate)).format('DD-MM-YYYY');
        transactionRequest.currencyCode.code = values.currencyCode;
        transactionRequest.chargeAmount = values.chargeAmount;
        if (values.disputeAmount !== '' && values.disputeAmount !== 'undefined') {
            transactionRequest.disputeAmount = values.disputeAmount;
        } else {
            transactionRequest.disputeAmount = null;
        }
        transactionRequest.failedTransactionReason.code = values.failedTransactionReason;
        transactionRequest.furtherDetails = values.furtherDetails;

        return transactionRequest;
    }

    function handleOnchange(e) {
        setDirty();
        formik.handleChange(e);
    }

    return (
        <div align="left">

            {Prompt}
            <Card className="raiseDisputeCard">
                <form id="raiseDisputeTranForm" onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                    <TransactionDetail formik={formik} handleOnchange={handleOnchange} reasonTxt={reasonTxt} reasonItems={reasonItems}
                        currencyTxt={currencyTxt} currencyItems={currencyItems} setDirty={setDirty} selectedDate={selectedDate}
                        setCurrencyTxt={setCurrencyTxt} dispAmtErrDisplay={dispAmtErrDisplay} setReasonTxt={setReasonTxt} handleDateChange={handleDateChange} />
                </form>
                <DialogSystemDown open={openIsSysDown} close={closeIsSysDownDialog} />
                <SessionExpiredPopup open={openSessionExpired} />
                <DialogPleaseWait open={plsWait} />
            </Card>
        </div>
    );
}
export default withRouter(InitiateDispute);
