export const currencyItems = [
    { name: 'ZWD', code: '716' },
    { name: 'CSK', code: '200' },
    { name: 'PTE', code: '620' },
    { name: 'BEF', code: '056' },
    { name: 'IEP', code: '372' },
    { name: 'FRF', code: '250' },
    { name: 'ESP', code: '724' },
    { name: 'LUF', code: '442' },
    { name: 'FIM', code: '246' },
    { name: 'DEM', code: '276' },
    { name: 'ITL', code: '380' },
    { name: 'XEU', code: '954' },
    { name: 'GRD', code: '300' },
    { name: 'AFN', code: '004' },
    { name: 'ALL', code: '008' },
    { name: 'DZD', code: '012' },
    { name: 'AON', code: '024' },
    { name: 'AZM', code: '031' },
    { name: 'ARS', code: '032' },
    { name: 'AUD', code: '036' },
    { name: 'ATS', code: '040' },
    { name: 'BSD', code: '044' },
    { name: 'BHD', code: '048' },
    { name: 'BDT', code: '050' },
    { name: 'AMD', code: '051' },
    { name: 'BBD', code: '052' },
    { name: 'BMD', code: '060' },
    { name: 'BTN', code: '064' },
    { name: 'BOB', code: '068' },
    { name: 'BWP', code: '072' },
    { name: 'BZD', code: '084' },
    { name: 'SBD', code: '090' },
    { name: 'BND', code: '096' },
    { name: 'MMK', code: '104' },
    { name: 'BIF', code: '108' },
    { name: 'BYB', code: '112' },
    { name: 'KHR', code: '116' },
    { name: 'CAD', code: '124' },
    { name: 'CVE', code: '132' },
    { name: 'KYD', code: '136' },
    { name: 'LKR', code: '144' },
    { name: 'CLP', code: '152' },
    { name: 'CNY', code: '156' },
    { name: 'COP', code: '170' },
    { name: 'KMF', code: '174' },
    { name: 'ZRN', code: '180' },
    { name: 'CRC', code: '188' },
    { name: 'HRK', code: '191' },
    { name: 'CUP', code: '192' },
    { name: 'CYP', code: '196' },
    { name: 'CZK', code: '203' },
    { name: 'DKK', code: '208' },
    { name: 'DOP', code: '214' },
    { name: 'ECS', code: '218' },
    { name: 'SVC', code: '222' },
    { name: 'ETB', code: '230' },
    { name: 'ERN', code: '232' },
    { name: 'EEK', code: '233' },
    { name: 'FKP', code: '238' },
    { name: 'FJD', code: '242' },
    { name: 'DJF', code: '262' },
    { name: 'GMD', code: '270' },
    { name: 'GHC', code: '288' },
    { name: 'GIP', code: '292' },
    { name: 'GTQ', code: '320' },
    { name: 'GNF', code: '324' },
    { name: 'GYD', code: '328' },
    { name: 'HTG', code: '332' },
    { name: 'HNL', code: '340' },
    { name: 'HKD', code: '344' },
    { name: 'HUF', code: '348' },
    { name: 'ISK', code: '352' },
    { name: 'INR', code: '356' },
    { name: 'IDR', code: '360' },
    { name: 'IRR', code: '364' },
    { name: 'IQD', code: '368' },
    { name: 'ILS', code: '376' },
    { name: 'JMD', code: '388' },
    { name: 'JPY', code: '392' },
    { name: 'KZT', code: '398' },
    { name: 'JOD', code: '400' },
    { name: 'KES', code: '404' },
    { name: 'KPW', code: '408' },
    { name: 'KRW', code: '410' },
    { name: 'KWD', code: '414' },
    { name: 'KGS', code: '417' },
    { name: 'LAK', code: '418' },
    { name: 'LBP', code: '422' },
    { name: 'LSL', code: '426' },
    { name: 'LVL', code: '428' },
    { name: 'LRD', code: '430' },
    { name: 'LYD', code: '434' },
    { name: 'LTL', code: '440' },
    { name: 'MOP', code: '446' },
    { name: 'MGF', code: '450' },
    { name: 'MWK', code: '454' },
    { name: 'MYR', code: '458' },
    { name: 'MVR', code: '462' },
    { name: 'MTL', code: '470' },
    { name: 'MRO', code: '478' },
    { name: 'MUR', code: '480' },
    { name: 'MXN', code: '484' },
    { name: 'MNT', code: '496' },
    { name: 'MDL', code: '498' },
    { name: 'MAD', code: '504' },
    { name: 'MZM', code: '508' },
    { name: 'OMR', code: '512' },
    { name: 'NAD', code: '516' },
    { name: 'NPR', code: '524' },
    { name: 'NLG', code: '528' },
    { name: 'ANG', code: '532' },
    { name: 'AWG', code: '533' },
    { name: 'VUV', code: '548' },
    { name: 'NZD', code: '554' },
    { name: 'NIO', code: '558' },
    { name: 'NGN', code: '566' },
    { name: 'NOK', code: '578' },
    { name: 'PKR', code: '586' },
    { name: 'PAB', code: '590' },
    { name: 'PGK', code: '598' },
    { name: 'PYG', code: '600' },
    { name: 'PEN', code: '604' },
    { name: 'PHP', code: '608' },
    { name: 'QAR', code: '634' },
    { name: 'ROL', code: '642' },
    { name: 'RUB', code: '643' },
    { name: 'RWF', code: '646' },
    { name: 'SHP', code: '654' },
    { name: 'STD', code: '678' },
    { name: 'SAR', code: '682' },
    { name: 'SCR', code: '690' },
    { name: 'SLL', code: '694' },
    { name: 'SGD', code: '702' },
    { name: 'SKK', code: '703' },
    { name: 'VND', code: '704' },
    { name: 'SIT', code: '705' },
    { name: 'SOS', code: '706' },
    { name: 'ZAR', code: '710' },
    { name: 'SDD', code: '736' },
    { name: 'SRG', code: '740' },
    { name: 'SZL', code: '748' },
    { name: 'SEK', code: '752' },
    { name: 'CHF', code: '756' },
    { name: 'SYP', code: '760' },
    { name: 'TJS', code: '762' },
    { name: 'THB', code: '764' },
    { name: 'TOP', code: '776' },
    { name: 'TTD', code: '780' },
    { name: 'AED', code: '784' },
    { name: 'TND', code: '788' },
    { name: 'TRL', code: '792' },
    { name: 'TMM', code: '795' },
    { name: 'UGX', code: '800' },
    { name: 'MKD', code: '807' },
    { name: 'EGP', code: '818' },
    { name: 'GBP', code: '826' },
    { name: 'TZS', code: '834' },
    { name: 'USD', code: '840' },
    { name: 'UYU', code: '858' },
    { name: 'UZS', code: '860' },
    { name: 'VEB', code: '862' },
    { name: 'WST', code: '882' },
    { name: 'YER', code: '886' },
    { name: 'ZMK', code: '894' },
    { name: 'TWD', code: '901' },
    { name: 'ZWR', code: '935' },
    { name: 'GHS', code: '936' },
    { name: 'VEF', code: '937' },
    { name: 'SDG', code: '938' },
    { name: 'RSD', code: '941' },
    { name: 'MZN', code: '943' },
    { name: 'AZN', code: '944' },
    { name: 'RON', code: '946' },
    { name: 'TRY', code: '949' },
    { name: 'XAF', code: '950' },
    { name: 'XCD', code: '951' },
    { name: 'XOF', code: '952' },
    { name: 'XPF', code: '953' },
    { name: 'XDR', code: '960' },
    { name: 'SRD', code: '968' },
    { name: 'MGA', code: '969' },
    { name: 'AOA', code: '973' },
    { name: 'BYR', code: '974' },
    { name: 'BGN', code: '975' },
    { name: 'CDF', code: '976' },
    { name: 'BAM', code: '977' },
    { name: 'EUR', code: '978' },
    { name: 'UAH', code: '980' },
    { name: 'GEL', code: '981' },
    { name: 'PLN', code: '985' },
    { name: 'BRL', code: '986' },
    { name: 'ZMW', code: '967' },
    { name: 'MRU', code: '929' },
    { name: 'STN', code: '930' },
    { name: 'VES', code: '928' }
];
