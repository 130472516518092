import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Card, Grid } from '@material-ui/core';
import '../component.css';

const AccessDenied = (props) => {

    return (

        <Card className="raiseDisputeCard">
            <Grid container  style={{ paddingBottom: '25px', paddingTop: '15px' }}>
                <Grid container justify="center">
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={8} >
                            <Typography variant="h4" align="center" >Your complaint cannot be processed at this time.</Typography>
                            <Typography variant="h6" align="center" >Outdated browsers can expose your computer to security risks.
                            To get the best experience on Discover.com,
                            you may need to update your browser to latest version and try again.
                            </Typography>
                            <Grid container justify="left" spacing={1} style={{ paddingTop: '25px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center"> For questions, please contact us at <strong>1-800-209-4006.</strong> Our operating hours are from 10am to 6pm(IST).</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Card>


    )
}
export default AccessDenied;
