export const transaction = {
	"merchantName": "",
	"chargeDate": "",
	"currencyCode": {
		"code": ""
	},
	"chargeAmount": "",
	"disputeAmount": "",
	"failedTransactionReason": {
		"code": ""
	},
	"furtherDetails": ""
}
