import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom';
import {
    isValid
} from './services/SessionService';

export class AuthenticatedRoute extends Component {

    render() {

        if (isValid()) {
            return <Route exact {...this.props} />
        } else {
            return <Redirect to="/" />
        }

    }
}

export default AuthenticatedRoute;
